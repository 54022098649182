import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import axios from 'axios';
//@ts-ignore
import Marquee3k from 'marquee3000';
import { connect } from 'react-redux';
import Head from 'next/head';
import { withTranslation } from '@i18n';
import {
  SectionFirst,
} from '../../components';
import './index.scss';
import './mobile/index.scss';
import { getApiServerUrl } from '../../helpers';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type Props = {
    t: (...args: any[]) => any;
    windowWidth?: number;
    language?: string;
    _rates?: any;
    _news?: any[];
};
const Index = (props: Props) => {
  const {
    language,
    t,
    _rates = {},
    _news = [],
  } = props;
  const DynamicSectionFaq = dynamic(() => import('../../components/SectionFaq'));
  const DynamicSectionInvest = dynamic(() => import('../../components/SectionInvestOld'));
  const DynamicSectionPartners = dynamic(() => import('../../components/SectionPartners'));
  const DynamicSectionSubscribe = dynamic(() => import('../../components/SectionSubscribe'));
  const DynamicSectionNews = dynamic(() => import('../../components/SectionNews'));
  const initialState = {
    openedVideoDialog2: false,
    rates: _rates,
    isMarqueeStopped: false,
    isMarqueeInitialized: false,
  };
  const [state, setState] = useState(initialState);
  const {
    openedVideoDialog2, rates, isMarqueeInitialized, isMarqueeStopped,
  } = state;
  const ratesData = {
    BTCUSD: {
      title: 'BTC / USD',
    },
    BTCUSD_COMMON: {
      title: 'BTC / USD',
    },
    LTCUSD: {
      title: 'LTC / USD',
    },
    ETHUSD: {
      title: 'ETH / USD',
    },
    ETHBTC: {
      title: 'ETH / BTC',
    },
    LTCBTC: {
      title: 'LTC / BTC',
    },
    BTCEUR: {
      title: 'BTC / EUR',
    },
    LTCEUR: {
      title: 'LTC / EUR',
    },
    USTUSD: {
      title: 'UST / USD',
    },
    USTEUR: {
      title: 'UST / EUR',
    },
    USTBTC: {
      title: 'UST / BTC',
    },
  };
  const initMarquee = () => {
    if (Object.keys(rates).length && !isMarqueeInitialized) {
      setState((prevState) => ({
        ...prevState,
        isMarqueeInitialized: true,
      }));
      Marquee3k.init({
        selector: 'marquee-wrapper',
      });
    }
  };
  useEffect((): any => {
    let cleanupFunction = true;
    async function fetchData() {
      if (!Object.keys(rates).length) {
        const ratesResponse = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/coin/rates`, {
          headers: {
            'X-localization': language === 'ru' ? 'ru' : 'en',
          },
        });
        if (cleanupFunction) {
          setState((prevState) => ({
            ...prevState,
            rates: ratesResponse.data.data,
          }));
        }
      }
    }
    fetchData();
    return () => (cleanupFunction = false);
  }, []);
  useEffect(() => {
    initMarquee();
  }, [rates]);
  return (
    <div className="Main" suppressHydrationWarning={true}>
      <Head>
        <title>{t('Облачный майнинг криптовалюты без вложений — CryptoUniverse')}</title>
        <meta
          name="description"
          content={t('Надежный облачный майнинг без вложений от CryptoUniverse Cloud Mining. Удобный и быстрый вывод денег с майнинга онлайн')}
        />
      </Head>
      <SectionFirst />
      <DynamicSectionInvest title="На чем еще можно заработать?" />
      <DynamicSectionFaq />
      <DynamicSectionPartners />
      <DynamicSectionNews _news={_news} />
      <DynamicSectionSubscribe />
    </div>
  );
};
Index.getInitialProps = async ({
  req,
}: any) => {
  if (req) {
    let news = [];
    try {
      const newsResponse = await axios.get(`${getApiServerUrl()}/api/news`);
      news = newsResponse.data.data;
    } catch (e) {
      console.log(e);
    }
    let rates = [];
    try {
      const ratesResponse = await axios.get(`${getApiServerUrl()}/api/coin/rates`);
      rates = ratesResponse.data.data;
    } catch (e) {
      console.log(e);
    }
    return {
      namespacesRequired: ['common'],
      _rates: rates,
      _news: news,
    };
  }
  return {
    namespacesRequired: ['common'],
  };
};
function mapStateToProps(state: any) {
  const { windowWidth } = state.info;
  const { language } = state.info;
  return {
    windowWidth,
    language,
  };
}
export default withTranslation('common')(connect(mapStateToProps)(Index));
