import React from 'react';
import { mainFirstSectionBg } from '@assets/img';
import { Container, Grid } from '@material-ui/core';
import Link from 'next/link';
import { withTranslation } from '@i18n';
import { useSelector } from 'react-redux';
import { Btn } from '../index';
import { languageReturner } from '../../helpers';

if (process.env.NODE_ENV === 'development') {
  require('./SectionFirst.scss');
  require('./mobile/index.scss');
}
const SectionFirst = (props: any) => {
  const {
    t,
    title = 'Сервис для майнинга CryptoUniverse ',
    button,
  } = props;
  const { language } = useSelector((state: any) => state.info);
  const componentButton = button || (
    <Link href="/cloudmining" as={`${languageReturner(language)}/cloudmining`}>
      <Btn flex justifyContent="space-between" color="blue">
        {t('Начать зарабатывать')}
        <i className="fa fa-caret-right ml-auto" />
      </Btn>
    </Link>
  );
  return (
    <div className="SectionFirst">
      <div className="main__first-section" style={{ backgroundImage: `url(${mainFirstSectionBg})` }}>
        <Container>
          <Grid container spacing={3} alignItems="center" className="static-page-header main__static-page-header">
            <Grid item xs={12} sm={12} md={7} className="main-page-title-fix">
              <h1 className="main-page-title">
                {(typeof title === 'string') ? t(title) : title}
              </h1>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <div className="main__first-section-description">
                {t('Мы создали самую большую и надежную площадку для майнинга в России, чтобы вы получали ежедневную прибыль месяц за месяцем.')}
              </div>
              <div className="main__button-container button_main_link">
                {componentButton}
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
export default withTranslation('common')(SectionFirst);
